<script setup>    
    import { ref, computed } from 'vue';
    import { useRoute, useRouter } from 'vue-router';
    import { useData } from '@/composables/Data';
    import { useBackend } from '@/composables/Backend';
    import { usePriceCheck } from '@/composables/PriceCheck';
    import Renderer from '@/components/Renderer.vue';
    import Spinner from '@/components/Spinner.vue';
    import { useCartman } from '@/composables/Cartman';
    
    const router = useRouter();
    const route = useRoute();
    const data = useData();    
    const backend = useBackend();
    const priceCheck = usePriceCheck(data);
    const cartman = useCartman();
    const rulerAdjustmentHelper = ref(false);

    const steps = Object.entries(data.steps).map((kv) => kv[1]);

    const heading = computed(() => {
        // if product has packages, use the package name
        if (data.currentPackage) {
            return data.currentPackage.value.label;
        }

        // otherwise, default to heading property
        return data.heading;
    });

    const error = ref('');

    const isWaitingOnBackend = ref(false);
    const confirmButtonText = computed(() => {
        if (isWaitingOnBackend.value) {
            return 'Loading...';            
        }
        else {
            return 'Add To Cart →'
        }
    })

    function toggleRulerAdjustmentHelper() {
        rulerAdjustmentHelper.value = !rulerAdjustmentHelper.value; // Toggle the value
    }

    function formatMeasurement(decimal) {
        const wholePart = Math.floor(decimal);
        const fractionPart = decimal - wholePart;
        const fraction = fractionPart ? convertDecimalFractionToFraction(fractionPart) : '';
        return fraction ? `${wholePart} ${fraction}` : `${wholePart}`;
    }

    function convertDecimalFractionToFraction(fractionPart) {
        const gcd = (a, b) => b ? gcd(b, a % b) : a;
        let denominator = 100;  // Supports up to 2 decimal places
        let numerator = fractionPart * denominator;
        const divisor = gcd(numerator, denominator);
        numerator /= divisor;
        denominator /= divisor;
        return `${Math.floor(numerator)}/${Math.floor(denominator)}`;
    }

    function goBack() {
        error.value = '';
        let goToRoute = '';

        if (data.currentPackage && data.currentPackage.value.skipMeasurer) {
            goToRoute = 'configure';
        }
        else {
            goToRoute = 'measure';
        }

        router.push({ name: goToRoute, params: route.params, query: route.query });
    }

    function confirm() {
        error.value = ''; // reset error in case this the not the first time they're hitting the confirm button        

        if (data.windowName.value == false) {
            error.value = 'Please enter a window name before continuing.';
        }
        else {
            isWaitingOnBackend.value = true;

            // Track the add to cart event
            if (typeof window.fbq === 'function') {
                window.fbq('track', 'AddToCart');
            }

            // the endpoint for editing an item is the same and as long as data.itemId is set then the backend will handle it properly
            backend.addCartItem(data)
            .then(result => {
                if (result == false || result.error) {
                    if (result.error) {
                        error.value = result.error;
                    }
                    else {
                        error.value = 'Sorry, an error occurred attempting to update your shopping cart. Please try again later or contact support.'
                    }
                    console.error(error.value);
                }
                else {
                    cartman.shouldReload.value = true;
                    cartman.isCartShown.value = true;
                }
            })
            .finally(() => {
                isWaitingOnBackend.value = false;
            });
        }
    }
</script>

<template>
    <div class="container-fluid product-confirm">
        <div class="row product-config">
            <div class="render-wrapper">
                <Renderer></Renderer>
            </div>
            <div class="confirm-wrapper">
                <div class="row product-title">
                    <div class="col product-options__header">
                        <h1 class="confirm-title">{{ heading }}</h1>
                        <h2 class="pretext color--chantilly">Confirm Your Selections</h2>
                        <!-- {{ data.steps.mountingHeight }} -->
                    </div>
                </div>
                <div class="row measurements">
                    <div class="col">
                        <div v-if="error">{{ error }}</div>
                        <p>Make installation a breeze by adding a room label below.</p>
                        <BFormInput class="text-input__field" v-model="data.windowName.value"></BFormInput>
                        <div v-for="(total, totalIndex) in data.getTotals()" :key="totalIndex">
                            <template v-for="(measuredValue, valueIndex) in total.values" :key="valueIndex">
                                <template v-if="total.layout == 'formula' || total.layout == 'individual'">                                    
                                    <template v-if="total.layout == 'formula'">
                                        <template v-if="valueIndex > 0 && valueIndex < total.values.length - 1"><div>+</div></template>
                                        <template v-if="valueIndex == total.values.length - 1"><div>=</div></template>
                                    </template>
                                    <div class="measured-value">
                                        <label>{{ measuredValue.label }}</label>
                                        <div>{{ formatMeasurement(measuredValue.getValue()) }}"</div>

                                        <div v-if="measuredValue.deduction === true">
                                            <div class="measured-value__deduction">
                                                <div class="ruler__additional-text is-italic">7/8" Deduction</div>
                                                <div class="ruler__additional-text is-result"></div>
                                                <img @click="toggleRulerAdjustmentHelper" src="https://assets-global.website-files.com/64da92de170fa515899c18fc/64da92de170fa515899c19d3_icon-info.svg" loading="lazy" width="14" data-everhem-helper="helperWindowDeduction" alt="" data-helper-ignore-closed="true" class="additional-text-info">
                                            </div>
                                            <div class="ruler__adjustment-container" v-if="rulerAdjustmentHelper">
                                                <div class="ruler__adjustment-box">
                                                    <div>
                                                        <h5>Deduction</h5>
                                                        <a class="modal--close" href="#" @click="rulerAdjustmentHelper = false"></a>
                                                    </div>
                                                    <div>
                                                        Total Width specified will be the bracket to bracket width of your product. For
                                                        Inside Mount cafe curtain hardware, we take a 7/8” deduction from the total width
                                                        entered to ensure the rod is a perfect fit.  i.e. If your total width is 47" your
                                                        rod length will be 46 1/8" for a bracket to bracket total width of 47"
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </template>
                                <template v-if="total.layout == 'text'">
                                    <p class="confirmer--total-text">{{ measuredValue.label }} = {{ measuredValue.getValue() }}</p>
                                </template>
                            </template>
                        </div>
                        <!-- {{ data }} -->
                    </div>
                </div>
                <div class="row configuration">
                    <div class="col">
                        <template v-for="(step, index) in steps" :key="index">
                            <template v-if="step.showOnConfirmation && (step.disabled == null || step.disabled.value != true)">
                                <div class="row">
                                    <div class="col-5 confirmation-label text-bold">{{ step.name }}:</div>
                                    <div class="col-7 confirmation-label text-bold text-right-aligned"><strong>{{ step.selected.value.label }}</strong></div>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="row total">
                    <div class="col-4 add-cart__total dark">
                        <span>
                            Total:
                            <!-- <template v-if="priceCheck.salePrice.value >= 0">{{ $formatCurrency(priceCheck.salePrice.value) }}</template> -->
                            <template v-if="priceCheck.price.value == priceCheck.salePrice.value">{{ $formatCurrency(priceCheck.salePrice.value) }}</template>
                            <template v-else-if="priceCheck.price.value >= priceCheck.salePrice.value"><span class="pricing--strikeout">{{ $formatCurrency(priceCheck.price.value) }}</span> {{ $formatCurrency(priceCheck.salePrice.value) }}</template>
                            <Spinner v-else></Spinner>                                                
                        </span>
                    </div>
                    <div class="col-4 color--lavaglas">
                        <a @click="goBack">← Edit</a>
                    </div>
                    <div class="col-4 add-cart__text color--lavaglas">
                        <a @click="confirm">{{ confirmButtonText }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>